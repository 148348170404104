<template>
  <form novalidate @submit.prevent="validateUser">
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <md-card >
          <md-card-header>
            <md-card-header-text>
              <div class="md-title" v-if="instance.id">Editando libro diario</div>
              <div class="md-title" v-if="!instance.id">Nuevo libro diario</div>
            </md-card-header-text>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/partidas')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('authorization')">
                  <label for="authorization" >Autorización</label>
                  <md-input type="text" name="authorization"
                  id="authorization" v-model="instance.authorization" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.authorization.required">
                El número de autorización es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field
                class="md-focused"
                :class="getValidationClass('date_authorization')">
                  <label for="date_authorization" >Fecha autoriación</label>
                  <md-input type="date" name="date_authorization"
                  id="date_authorization" v-model="instance.date_authorization"
                  :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.date_authorization.required">
                La fecha de autorización es requerido</span>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field >
                  <label for="period">Año fiscal</label>
                  <md-select v-model="instance.fiscal_year">
                  <md-option value="">----</md-option>
                  <md-option :value="item.id"
                    v-for="(item, key) in list_years"
                    :key="key">{{item.year}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <br>
                <md-switch v-model="instance.status">
                  <span v-if="instance.status">Activo</span>
                  <span v-if="!instance.status">Inactivo</span>
                </md-switch>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending" />
          <md-card-actions>
            <md-button
            type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
          </md-card-actions>
        </md-card>
        <br>
        <br>
        <br>
      </div>
      <div class="md-layout-item md-size-50">
      </div>
      <md-snackbar
        :md-active.sync="userSaved">El documento {{ docuemt }} ha sido actualizado!</md-snackbar>
    </div>
  </form>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'FormBookDiary',
  created(){
    if (this.$route.params.id) {
      this.instance.id = this.$route.params.id
      this.retrieveInvoice();
    }
    this.get_list_years()
  },
  mixins: [validationMixin],
  data() {
    return {
      list_libros: [],
      sending_request: null,
      base: process.env.VUE_APP_BASE_URL,
      list_years: [], 
      instance: {
        authorization: null,
        fiscal_year: null,
        date_authorization: null,
        status: true,
      },
      choosen_year: [
        {value:'2021', text: '2021'},
        {value:'2022', text: '2022'},
        {value:'2023', text: '2023'},
        {value:'2024', text: '2024'},
        {value:'2025', text: '2025'},
        {value:'2026', text: '2026'},
        {value:'2027', text: '2027'},
        {value:'2028', text: '2028'},
        {value:'2029', text: '2029'},
      ],
      url: 'diary_book/',
      url_fiscal_year: 'fiscal_year',
      userSaved: false,
      sending: false,
      docuemt: null,
    };
  },
  validations: {
    instance: {
      authorization: {
        required,
      },
      date_authorization: {
        required,
      },
      status: {
        required,
      },
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      this.$swal.fire('Error!', result, 'error');
      return false;
    },
    list() {
      let payload = {}
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest_with_url(this.url_fiscal_year, payload).then((data) => {
        self.sending_request = false;
        self.list_years = data.data;
      }).catch((error) => {
        self.sending_request = false;
        self.handlerError(error);
      });
      return null;
    },
    get_list_libros(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      const self = this;
      this.sendRequest(payload, this.url_rubros).then((data) => {
        self.list_libros = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    saveOrUpdate () {
      const payload = this.instance
      const self = this
      this.sending = true
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.docuemt = `${self.instance.authorization}`
          self.userSaved = true
          self.sending = false
           // self.clearForm()
          // setTimeout(() => self.go_to('/partidas'), 2000);
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.company = this.$store.state.company_obj.id
      // payload.store = this.$store.state.store_obj.id
      // payload.fiscal_year = localStorage.getItem('id_fiscal_year')

      this.postRequest(payload).then(function (data) {
        self.docuemt = `${self.instance.authorization}`
        self.userSaved = true
        self.sending = false
        //self.clearForm()
        // setTimeout(() => self.go_to('/partidas'), 2000);
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async retrieveInvoice () {
      const self = this
      let payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    async sendRequest_with_url(url, payload) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
